import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"

import fbRecommendations from "./fb-recommendations.svg"
import Stars from "./Stars"

import PropTypes from "prop-types"

var classNames = require("classnames")

export default function FeaturedSocial(props) {
  if (props.inlineStars) {
    return (
      <div className="featured-social inline-stars">
        <div>
          <a
            className="link-wrapper"
            href={props.social.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>{props.heading}</h3>
            <div className="review-icons">
              <Stars rating={props.social.rating} />
            </div>
          </a>
          <a
            className="link-wrapper"
            href={props.social.url}
            target="_blank"
            rel="noopener noreferrer"
          ></a>
        </div>
        <a
          href={props.social.url}
          className="reviews-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="reviews-number">{props.social.totalReviews}</span>{" "}
          <span className="reviews-word">
            {props.language === "es" ? "Reseñas" : props.reviewNoun}
          </span>
        </a>
      </div>
    )
  } else {
    return (
      <div className="featured-social">
        <a
          className="link-wrapper"
          href={props.social.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <h3>{props.heading}</h3>
        </a>
        <a
          className="link-wrapper"
          href={props.social.url}
          target="_blank"
          rel="noopener noreferrer"
        ></a>
        <a href={props.social.url} className="reviews-link" target="_blank">
          {props.social.totalReviews}{" "}
          {props.language === "es" ? "Reseñas" : props.reviewNoun}
        </a>
        <div className="review-icons">
          <h4 className="google-rating">{props.social.rating.toFixed(1)}</h4>
          <Stars rating={props.social.rating} />
        </div>
      </div>
    )
  }
}
